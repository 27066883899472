<template>
    <div class="goods-copy-container">
        <div class="goods-copy-top">
            <van-nav-bar title="复制商品" fixed/>
        </div>
        <div class="goods-copy-form">
            <div class="goods-copy-from">
                <van-cell-group>
                    <van-cell title="来源城市" :value="info.fromCityName||'请选择来源城市'" icon="my iconfont icon-chengshi"
                              is-link
                              @click="picker.fromCity=true"/>
                    <van-cell title="商品状态" :value="info.goodsStatusName||'请选择来源城市商品状态'"
                              icon="my iconfont icon-zhuangtai"
                              is-link
                              @click="picker.goodsStatus=true"/>
                    <div v-if="info.goodsStatus == -1">
                        <van-field v-model="goodsTitle"
                                   left-icon="search"
                                   center
                                   clearable
                                   label="商品查询"
                                   placeholder="请输入商品名称">
                            <van-button slot="button" size="small" icon="search" type="info" @click="onSearchGoods">查询
                            </van-button>
                        </van-field>
                        <div class="goods-copy-search-goods">
                            <van-list v-if="goodsList.length > 0">
                                <van-cell
                                        v-for="(goods, index) in goodsList"
                                        :key="index"
                                        :title="goods.title">
                                    <van-icon
                                            slot="right-icon"
                                            name="plus"
                                            style="line-height: inherit;"
                                            @click="onAddGoods(goods, index)"/>
                                </van-cell>
                            </van-list>
                        </div>
                        <van-cell title="已选商品" :value="info.goods.length" icon="my iconfont icon-shangpin" is-link
                                  :arrow-direction="showSelectGoods ? 'down' : ''"
                                  @click="showSelectGoods=!showSelectGoods"/>
                        <div class="goods-copy-selecte-goods" v-if="showSelectGoods">
                            <van-list v-if="info.goods.length > 0">
                                <van-cell
                                        v-for="(goods, index) in info.goods"
                                        :key="index"
                                        :title="goods">
                                    <van-icon
                                            slot="right-icon"
                                            name="cross"
                                            style="line-height: inherit;"
                                            @click="onDelGoods(index)"/>
                                </van-cell>
                            </van-list>
                            <van-divider v-else>未选择商品</van-divider>
                        </div>
                    </div>
                </van-cell-group>
            </div>
            <div class="goods-copy-to">
                <van-divider dashed>
                    复制到
                </van-divider>
                <van-cell-group>
                    <van-cell title="目标城市" :value="info.toCityName||'请选择目标城市'" icon="my iconfont icon-chengshi" is-link
                              @click="picker.toCity=true"/>
                </van-cell-group>
            </div>
        </div>
        <div class="goods-copy-btn">
            <van-button type="info" size="large" icon="my iconfont icon-kaishi" @click="copy">开始复制</van-button>
        </div>
        <div class="goods-copy-popup">
            <!-- 来源城市 -->
            <van-popup v-model="picker.fromCity" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="来源城市"
                        :columns="fromCityList"
                        @confirm="onChangeFromCity"
                        @cancel="picker.fromCity=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 商品状态 -->
            <van-popup v-model="picker.goodsStatus" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="商品状态"
                        :columns="fromGoodsStatusList"
                        @confirm="onChangeGoodsStatus"
                        @cancel="picker.goodsStatus=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 目标城市 -->
            <van-popup v-model="picker.toCity" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="目标城市"
                        :columns="toCityList"
                        @confirm="onChangeToCity"
                        @cancel="picker.toCity=false"
                        :show-toolbar="true"
                />
            </van-popup>
        </div>
        <van-overlay :show="loading" class="goods-copy-loading">
            <van-loading type="spinner" color="#1989fa">正在复制...</van-loading>
        </van-overlay>
    </div>
</template>

<script>
    export default {
        name: 'goods-copy',
        props: ['node'],
        data() {
            return {
                info: {
                    fromCityId: 0,
                    fromCityName: '',
                    goodsStatus: '',
                    goodsStatusName: '所有商品',
                    toCityId: 0,
                    toCityName: '',
                    goods: []
                },
                goodsTitle: '',
                goodsList: [],
                showSelectGoods: true,
                fromCityList: [],
                fromGoodsStatusList: [
                    {index: '', text: '所有商品'},
                    {index: 0, text: '已下架商品'},
                    {index: 1, text: '上架中商品'},
                    {index: -1, text: '手动选择'}
                ],
                toCityList: [],
                picker: {
                    fromCity: false,
                    goodsStatus: false,
                    toCity: false
                },
                loading: false
            }
        },
        mounted: function () {
            this.loadCity();
        },
        computed: {
            offUserList: function () {
                return this.fromUserList.filter(function (item) {
                    return item.status == 0;
                });
            },
            onUserList: function () {
                return this.toUserList.filter(function (item) {
                    return item.status == 1;
                });
            }
        },
        methods: {
            copy() {
                let that = this;
                if (that.info.fromCityId == 0) {
                    return that.$toast('请选择来源城市');
                }
                if (that.info.toCityId == 0) {
                    return that.$toast('请选择目标城市');
                }
                if (that.info.toCityId == that.info.fromCityId) {
                    return that.$toast('目标城市和来源城市不能相同');
                }
                that.loading = true;
                that.$api.goods.copy(that.info).then(response => {
                    that.loading = false;
                    let res = response.data;
                    if (res.code == 1) {
                        that.$dialog.alert({
                            title: '操作成功',
                            message: res.msg
                        });
                        if (that.node) {
                            that.$emit('onClose', that.node);
                        }
                    } else {
                        that.$toast.fail(res.msg);
                    }
                })
            },
            loadCity() {
                let that = this;
                that.$api.user.loadCity().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.fromCityList = that.toCityList = res.data;
                    }
                })
            },
            onChangeFromCity(value) {
                this.picker.fromCity = false;
                this.info.fromCityId = value.index;
                this.info.fromCityName = value.text;
            },
            onChangeToCity(value) {
                this.picker.toCity = false;
                this.info.toCityId = value.index;
                this.info.toCityName = value.text;
            },
            onChangeGoodsStatus(value) {
                this.picker.goodsStatus = false;
                this.info.goodsStatus = value.index;
                this.info.goodsStatusName = value.text;
            },
            onAddGoods(goods, index) {
                if (this.info.goods.length >= 15)
                    this.$toast('每次复制不能超过15个商品');
                this.goodsList.splice(index, 1);
                if (this.info.goods.indexOf(goods.title) == -1) {
                    this.info.goods.push(goods.title);
                } else {
                    this.$toast(goods.title + '已经添加过了');
                }
            },
            onDelGoods(index) {
                this.info.goods.splice(index, 1);
            },
            onSearchGoods() {
                let that = this;
                that.goodsList = [];
                that.$api.goods.searchByName({cityId: that.info.fromCityId, title: that.goodsTitle}).then(response => {
                    that.goodsTitle = '';
                    let res = response.data;
                    if (res.code == 1) {
                        that.goodsList = res.data;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .goods-copy-form {
        position: fixed;
        width: 100%;
        top: 46px;
        bottom: 50px;
        overflow-y: auto;
    }

    .goods-copy-btn {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .goods-copy-loading {
        z-index: 2 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .goods-copy-search-goods, .goods-copy-selecte-goods {
        max-height: 220px;
        overflow: hidden;
        overflow-y: scroll;
    }
</style>
